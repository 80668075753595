'use strict';

module.exports = function () {
    var slickConfigs = require('org/config/slickConfigs');
    var $educationLayout = $('.js-lm-education-nav').closest('.js-lm-education-layout');
    var $educationNavigation = $('.js-lm-education-nav');
    var $educationNavItems = $('.js-lm-education-nav-item');
    var $sectionContent = $('.js-lm-section-content');
    var educationArticleClass = '.js-lm-education-article, .js-lm-featured-article';
    var $modalContent = $('.js-lm-education-modal .js-modal-content');
    var progressBarHeight = 6.5;
    var startX; // cursor position on article.mousedown
    var startY; // cursor position on article.mouseup
    var dragThreshold = 5; // if user moves cursor on >5px don't recognize as click event
    var isScrolling = false;

    /**
     * Sets a cookie with the given name, value, and expiration days.
     *
     * @param {string} name - The name of the cookie.
     * @param {string} value - The value to store in the cookie.
     * @param {number} days - The number of days the cookie will persist.
     */
    function setCookie(name, value, days) {
        let expires = '';
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    }

    /**
     * Retrieves the value of a cookie with the specified name.
     *
     * @param {string} name - The name of the cookie to retrieve.
     * @returns {string|null} - The value of the cookie, or null if the cookie does not exist.
     */
    // eslint-disable-next-line no-unused-vars, require-jsdoc
    function getCookie(name) {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    /**
     * Initialize education carousel
     * @param {boolean} isModal - is Modal carousel
     * @returns {jQuery} - Initialized carousel
     */
    function initCarousel(isModal = false) {
        var $carousel = isModal ? $('.js-lm-education-modal.show .js-lm-education-modal-carousel') : $('.js-lm-section-content.show .js-lm-education-carousel');
        if ($carousel.is('.slick-initialized')) return $carousel;
        var config = isModal ? slickConfigs.educationModalCarousel : slickConfigs.educationCarousel;

        $carousel.slick(config);

        return $carousel;
    }

    /**
     * Show modal with content page or video
     * @param {jQuery} $clickedArticle - Clicked education article
     * @param {jQuery} $activeModal - Active modal
     * @returns {void}
     */
    function showModal($clickedArticle, $activeModal) {
        var $modalDialog = $activeModal.find('.js-modal-dialog');
        var $modalBody = $activeModal.find('.js-modal-body');
        var $modalFooter = $activeModal.find('.modal-footer');
        var articleType = $clickedArticle.data('type') === 'content' ? 'content' : 'video'; // just for transparency
        var $modalCarousel = $activeModal.find('.js-lm-education-modal-carousel');

        $modalFooter.css('opacity', '0');

        if (articleType === 'video') {
            $modalDialog.addClass('modal-dialog-centered');
        }

        $activeModal.one('shown.bs.modal', function () {
            if (articleType === 'content') {
                var contentURL = $clickedArticle.data('content-url');
                var contentID = $clickedArticle.data('content-id');
                var $iframe = $('<iframe>', {
                    class: 'lm-education-content-iframe',
                    src: contentURL,
                    frameborder: 0,
                    style: 'width: 100%; height: 100%; overflow: hidden; display: none;'
                });

                // Show the loader in the modal body while the content is being loaded
                $modalBody.html(`
                    <svg class="lm-education-loader d-block mx-auto" width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.44 37.88C29.6241 37.88 37.88 29.6241 37.88 19.44C37.88 9.25587 29.6241 1 19.44 1C9.25587 1 1 9.25587 1 19.44C1 29.6241 9.25587 37.88 19.44 37.88Z" stroke="#1C1A1A" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M37.5686 19.4386C24.2586 22.0986 22.0986 24.2586 19.4386 37.5686C16.7786 24.2586 14.6186 22.0986 1.30859 19.4386C14.6186 16.7786 16.7786 14.6186 19.4386 1.30859C22.0986 14.6186 24.2586 16.7786 37.5686 19.4386Z" stroke="#1C1A1A" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M32.2611 32.6088C20.9711 25.0788 17.9211 25.0788 6.62109 32.6088C14.1511 21.3188 14.1511 18.2688 6.62109 6.96875C17.9111 14.4988 20.9611 14.4988 32.2611 6.96875C24.7311 18.2587 24.7311 21.3088 32.2611 32.6088Z" stroke="#1C1A1A" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                `);

                $modalBody.append($iframe);
                $iframe.on('load', function () {
                    var iframeDocument = this.contentWindow.document;
                    $('.lm-education-loader').removeClass('d-block').hide();
                    // eslint-disable-next-line require-jsdoc
                    function adjustIframeHeight() {
                        $modalBody.height(iframeDocument.body.scrollHeight);
                    }
                    adjustIframeHeight();
                    // Monitor for changes in the iframe (for dynamically loaded content)
                    // eslint-disable-next-line no-undef
                    const resizeObserver = new ResizeObserver(() => {
                        adjustIframeHeight();
                    });

                    resizeObserver.observe(iframeDocument.body);

                    iframeDocument.body.style.backgroundColor = '#fff';
                    iframeDocument.body.style.overflow = 'hidden';
                    iframeDocument.querySelector('#promotionBanner').style.display = 'none';
                    iframeDocument.querySelector('.header-global').style.display = 'none';
                    iframeDocument.querySelector('.footer-trusted-site').style.display = 'none';
                    iframeDocument.querySelector('#footercontent').style.display = 'none';
                    $iframe.css('display', 'block');
                    $modalFooter.css('opacity', '1');

                    // Handle links inside the iframe
                    const links = iframeDocument.querySelectorAll('a');

                    links.forEach(link => {
                        link.addEventListener('click', function (event) {
                            event.preventDefault();
                            const target = this.getAttribute('target');

                            if (target === '_blank') {
                                window.open(this.href, '_blank');
                            } else {
                                window.location.href = this.href;
                            }
                        });
                    });
                });

                // Hide the clicked article from the carousel and initialize the carousel
                $modalCarousel.find(`.js-lm-education-article[data-content-id="${contentID}"]`).first().addClass('hidden-article');
                initCarousel(true);
                $modalCarousel.slick('slickFilter', ':not(:has(.hidden-article))');
            } else if (articleType === 'video') {
                var videoURL = $clickedArticle.data('video-url');
                $modalBody.html(`
                    <iframe
                        class="js-lm-education-video-iframe lm-education-video-iframe"
                        src="${videoURL}"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen>
                    </iframe>
                `);

                $modalBody.spinner().start();

                var $educationVideoIframe = $('.js-lm-education-video-iframe');

                $educationVideoIframe.on('load', function () {
                    $modalBody.spinner().stop();
                });
            }
        });

        $activeModal.addClass(articleType).modal('show');
        $activeModal.one('hidden.bs.modal', function () {
            $modalBody.removeAttr('style').empty();
            $modalDialog.removeClass('modal-dialog-centered');
            $activeModal.removeClass('content video');
            $modalCarousel.slick('slickUnfilter');
            $modalCarousel.find('.js-lm-education-article').removeClass('hidden-article');
        });
    }

    $(window).on('resize orientationchange', function () {
        $('.js-lm-education-carousel.slick-initialized').slick('unslick'); // Unslick the slider
        initCarousel();
    });

    // Initialize carousel with education articles when the section is shown
    $sectionContent.on('shown.bs.collapse', function () {
        initCarousel();
    });

    $('body').on('mousedown touchstart', educationArticleClass, function (e) {
        startX = e.clientX || e.originalEvent.touches[0].clientX;
        startY = e.clientY || e.originalEvent.touches[0].clientY;
        isScrolling = false;
    });

    // Show education modal on article click
    $('body').on('mousemove touchmove', educationArticleClass, function (e) {
        var moveX = e.clientX || e.originalEvent.touches[0].clientX;
        var moveY = e.clientY || e.originalEvent.touches[0].clientY;

        var diffX = Math.abs(moveX - startX);
        var diffY = Math.abs(moveY - startY);

        // If user moves finger more than 5px in any direction, set isScrolling to true
        if (diffX > dragThreshold || diffY > dragThreshold) {
            isScrolling = true;
        }
    });

    // Show education modal on article click
    $('body').on('mouseup touchend', educationArticleClass, function (e) {
        // If user was scrolling, don't trigger the click event
        if (isScrolling) {
            return;
        }

        var endX = e.clientX || e.originalEvent.changedTouches[0].clientX;
        var endY = e.clientY || e.originalEvent.changedTouches[0].clientY;
        var diffX = Math.abs(endX - startX);
        var diffY = Math.abs(endY - startY);

        if (diffX > dragThreshold || diffY > dragThreshold) {
            return;
        }

        var $this = $(this);
        var $modal = $('.js-lm-section-content.show .js-lm-education-modal');

        if ($modal.is('.show')) {
            $modal.one('hidden.bs.modal', function () {
                showModal($this, $modal);
            });

            $modal.modal('hide');
        } else {
            showModal($this, $modal);
        }
    });

    // Fill progress bar for the modal with education content
    $modalContent.on('scroll', function () {
        var $progressBar = $(this).find('.js-progress-bar');
        var clientHeight = this.clientHeight;
        var scrollHeight = this.scrollHeight;
        var scrolledY = this.scrollTop;

        var percentageScrolled = (scrolledY / (scrollHeight - clientHeight - progressBarHeight)) * 100;
        $progressBar.css('width', (percentageScrolled > 100 ? 100 : percentageScrolled) + '%');
    });

    // Adding id to each section content of the accordion and show the first education section
    $sectionContent.each(function (index) {
        $(this).attr('id', `lm-section-content-${index}`);
    });

    // Create navigation section for education component
    $educationNavItems.each(function (index) {
        $educationNavigation.append(`
            <div class="lm-education-nav-item js-lm-education-alternative-heading"  data-alternative-heading="${$(this).attr('data-alternative-heading')}" data-toggle="collapse" data-target="#lm-section-content-${index}" aria-expanded="false" aria-controls="lm-section-content-${index}">
                <span class="label-medium">${$(this).text()}</span>
            </div>
        `);
    });

    $('body').on('click', '.lm-education-nav-item', function () {
        const targetValue = $(this).data('target').replace('#lm-section-content-', '');
        setCookie('selectedSection', targetValue, 7); // Store value for 7 days

        $('.js-lm-education-headline').text($(this).data('alternativeHeading') || $('.js-lm-education-headline').data('headline'));
    });

    // Select on init page load by default
    const $tabToSelect = $educationLayout.find('.js-lm-section-content.selected');

    if ($tabToSelect.length) {
        const tabToSelectId = $tabToSelect.attr('id');
        const $tabToClick = $educationLayout.find(`.js-lm-education-nav  div[data-target="#${tabToSelectId}"]`);

        if ($tabToClick.attr('aria-expanded') !== 'true') {
            $tabToClick.trigger('click');
        }
    }

    // Apply user selection. I don't think that it is intender behavior
    // const selectedSection = getCookie('selectedSection') || 0;
    // $(`.lm-education-nav-item[data-target="#lm-section-content-${selectedSection}"]`).trigger('click');

    initCarousel();
};
