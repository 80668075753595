'use strict';

var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./loveAndMarriageStudio/loveStoriesCarousel'));
    processInclude(require('./loveAndMarriageStudio/welcome'));
    processInclude(require('./loveAndMarriageStudio/education'));
    processInclude(require('./loveAndMarriageStudio/ringBuilder'));
    processInclude(require('./loveAndMarriageStudio/wishlist'));
    processInclude(require('./loveAndMarriageStudio/drReturningCarousel'));
    processInclude(require('./loveAndMarriageStudio/quickView'));
});
