'use strict';

var device = require('org/utils/device');

var activeDotHtml = `
    <svg class="active-dot" width="12" height="12" viewBox="0 0 12 12">
        <circle class="background-circle" cx="6" cy="6" r="5"></circle>
        <circle class="progress-circle" cx="6" cy="6" r="5"></circle>
    </svg>
`;
var unactiveDotHtml = '<div class="unactive-dot"></div>';
var carouselRotationDuration = 5000; // 5s


/**
 * Sets Navigation Dots according to the image postion on mobile
 * and details block on desktop
 * @param {JQuery} $carousel - active carousel
 * @returns {void}
 */
function setNavDotsPosition($carousel) {
    // jQuery elements
    var $navDots = $carousel.find('.js-ls-carousel-nav-dots');
    var $activeSlide = $carousel.find('.js-ls-slide.active');
    var $detailsWrapper = $activeSlide.find('.details-wrapper');
    var $slideImage = $activeSlide.find('.js-ls-slide-image');

    // Positions
    var carouselPosition = $carousel[0].getBoundingClientRect();
    var detailsWrapperPosition = $detailsWrapper[0].getBoundingClientRect();
    var slideImagePosition = $slideImage.length ? $slideImage[0].getBoundingClientRect() : detailsWrapperPosition;
    var navDotsOffset = {};

    if ($carousel.hasClass('js-legacy-carousel')) {
        if (device.desktop()) {
            navDotsOffset.left = $slideImage.outerWidth();
        } else {
            navDotsOffset.left = 0;
        }

        $navDots.css({
            left: navDotsOffset.left,
            opacity: 1
        });
    } else {
        if (device.desktop()) {
            navDotsOffset.left = detailsWrapperPosition.left - carouselPosition.left;
            navDotsOffset.bottom = carouselPosition.bottom - detailsWrapperPosition.bottom;
        } else {
            navDotsOffset.left = slideImagePosition.left - carouselPosition.left;
            navDotsOffset.bottom = carouselPosition.bottom - slideImagePosition.bottom;
        }

        $navDots.css({
            left: navDotsOffset.left,
            bottom: navDotsOffset.bottom,
            opacity: 1
        });
    }
}

/**
 * Clear the rotation interval of the Carousel
 * @param {JQuery} $carousel - active carousel
 * @returns {void}
 */
function clearRotationInterval($carousel) {
    if (window['carouselRotationInterval' + $carousel.attr('data-index')]) {
        clearInterval(window['carouselRotationInterval' + $carousel.attr('data-index')]);
        $carousel.find('.js-ls-carousel-nav-dots').addClass('disable-rotation');
        window['carouselRotationInterval' + $carousel.attr('data-index')] = null;
    }
}

/**
 * Switch the slide
 * @param {JQuery} $activeDot - active navigation dot
 * @returns {void}
 */
function switchSlide($activeDot) {
    var $carousel = $activeDot.closest('.js-ls-carousel');
    var $prevActiveSlide = $carousel.find('.js-ls-slide.active');
    var $prevActiveDot = $carousel.find('.js-nav-dot.active');
    var $navDots = $carousel.find('.js-ls-carousel-nav-dots');

    $navDots.css('opacity', 0);
    $prevActiveSlide.removeClass('active');
    $prevActiveDot.removeClass('active').html(unactiveDotHtml);

    $carousel.find(`.js-ls-slide[data-id="${$activeDot.attr('data-id')}"]`).addClass('active');
    $activeDot.addClass('active');
    $activeDot.html(activeDotHtml);

    setNavDotsPosition($carousel);
}

/**
 * Initialize the carousel
 * @returns {void}
 */
function initCarousel() {
    $(window).on('load', function () {
        var $carousel = $('.js-ls-carousel');

        if (!$carousel.length) return;

        for (let i = 0; i < $carousel.length; i++) {
            let $carouselItem = $($carousel[i]);
            let $slides = $carouselItem.find('.js-ls-slide');

            if (!$slides.length) return;

            $slides.first().addClass('active');

            let navDots = `
                <div class="js-ls-carousel-nav-dots ls-carousel-nav-dots">
                    ${$slides.map(function (index) {
                        $(this).attr('data-id', index);

                        return `<div class="js-nav-dot nav-dot ${index === 0 ? 'active' : ''}" data-id="${index}">
                            ${index === 0 ? activeDotHtml : unactiveDotHtml}
                        </div>`;
                    }).get().join('')}
                </div>
            `;

            $carouselItem.append(navDots);
            $carouselItem.attr('data-index', i);
            setNavDotsPosition($carouselItem);

            window['carouselRotationInterval' + i] = setInterval(function () {
                let $prevActiveDot = $carouselItem.find('.js-nav-dot.active');
                let $newActiveDot = $prevActiveDot.next();

                if (!$newActiveDot.length) {
                    $newActiveDot = $carouselItem.find('.js-nav-dot').first();
                }

                switchSlide($newActiveDot);
            }, carouselRotationDuration);

            $(window).on('resize orientationchange', function () {
                setNavDotsPosition($carouselItem);
            });
        }
    });
}

/**
 * Process slider navigation
 * @returns {void}
 */
function handleInteraction() {
    $('body').on('click', '.js-nav-dot', function () {
        var $this = $(this);

        if (!$this.is('.active')) {
            switchSlide($this);
        }

        var $carousel = $this.closest('.js-ls-carousel');

        clearRotationInterval($carousel);
    });

    if (device.desktop()) return;

    // track tap and swipes on mobile for slider navigation
    $('.js-ls-slide-image').on('touchstart', function (touchStartEvent) {
        var $this = $(this);
        var $carousel = $this.closest('.js-ls-carousel');

        var $prevActiveDot = $carousel.find('.js-nav-dot.active');
        var startX = touchStartEvent.touches[0].clientX;
        var startY = touchStartEvent.touches[0].clientY;
        var startTime = new Date().getTime();

        $this.one('touchend', function (touchendEvent) {
            var endX = touchendEvent.changedTouches[0].clientX;
            var endY = touchendEvent.changedTouches[0].clientY;
            var endTime = new Date().getTime();

            var diffX = endX - startX;
            var diffY = endY - startY;
            var diffTime = endTime - startTime;

            // Define thresholds for distinguishing between tap and swipe
            var swipeThreshold = 30; // Minimum distance (in pixels) for a swipe
            var timeThreshold = 300; // Maximum time (in milliseconds) for a tap

            if (Math.abs(diffX) < swipeThreshold && Math.abs(diffY) < swipeThreshold && diffTime < timeThreshold) { // Tap condition
                clearRotationInterval($carousel);
            } else if (diffX >= swipeThreshold && $prevActiveDot.prev().length) { // Swipe right condition
                clearRotationInterval($carousel);
                switchSlide($prevActiveDot.prev());
            } else if (diffX <= -swipeThreshold && $prevActiveDot.next().length) { // Swipe left condition
                clearRotationInterval($carousel);
                switchSlide($prevActiveDot.next());
            }
        });
    });
}

module.exports = {
    initCarousel: initCarousel,
    handleInteraction: handleInteraction
};
