'use strict';

module.exports = function () {
    // jQuery objects start

    // Hero Banner
    var $getStartedBtn = $('.js-get-started-btn');

    // Welcome Component
    var $welcomeRow = $('.js-lm-welcome-row');
    var $welcomeTile = $('.js-lm-welcome-tile');
    var $resetSelection = $('.js-lm-welcome-reset');
    var $welcomeStrategyBtn = $('.js-lm-welcome-strategy');
    var $welcomeOverlay = $('.js-lm-welcome-overlay');
    var $logo = $('.js-lm-welcome-logo');
    var $logoContainer = $('.js-lm-logo-container');
    var $welcomeTopSectionItem = $('.js-lm-top-section-item:has(.js-lm-welcome-layout)');

    // Other
    var $topSection = $('.js-lm-top-section');
    var $body = $('body');
    // jQuery objects end

    /**
     * Set logo offset, set the row height with Selection Tiles, and initialize the Welcome Component
     * @return {void}
     */
    function initWelcomeComponent() {
        if (!$logoContainer.length) return;

        var logoOffsetLeft = $logoContainer[0].getBoundingClientRect().left;
        $logo.css('left', logoOffsetLeft); // Is done with JS in order to minimize the code (much more code is required with css)
        var rowHeight = $welcomeRow.height();
        $welcomeRow.height(rowHeight);
        $welcomeRow.addClass('initialized');
    }

    initWelcomeComponent();

    // Load the YouTube IFrame API script dynamically
    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // YouTube videos
    var $playerInspiration = $('#player-inspiration');
    var $playerLearn = $('#player-learn');

    var playerInspiration = null;
    var playerLearn = null;

    // <video> videos
    var $videoPlayerInspiration = $('#video-player-inspiration');
    var $videoPlayerLearn = $('#video-player-learn');

    // Stop videos at first load
    if ($videoPlayerInspiration.length) $videoPlayerInspiration.trigger('pause');
    if ($videoPlayerLearn.length) $videoPlayerLearn.trigger('pause');


    // This function will be called when the API is fully loaded
    window.onYouTubeIframeAPIReady = function () {
        if ($playerInspiration.length > 0) {
            // Initialize the first player
            playerInspiration = new window.YT.Player($playerInspiration.attr('id'), {
                width: '100%',
                videoId: $playerInspiration.data('video-id') // Replace with the actual YouTube video ID
            });
        }

        if ($playerLearn.length > 0) {
            // Initialize the second player
            playerLearn = new window.YT.Player($playerLearn.attr('id'), {
                width: '100%',
                videoId: $playerLearn.data('video-id') // Replace with the actual YouTube video ID
            });
        }
    };

    $getStartedBtn.on('click', function () {
        $topSection.attr('data-active-component', 'welcome');
    });

    // Select Strategy
    $welcomeTile.on('mouseover', function () {
        var $this = $(this);
        $welcomeTile.not(this).addClass('unactive');

        if ($videoPlayerInspiration.length && $videoPlayerLearn.length) {
            if ($this.is('#inspiration')) {
                $videoPlayerInspiration.trigger('play');
            } else if ($this.is('#learn')) {
                $videoPlayerLearn.trigger('play');
            }
        } else {
            // eslint-disable-next-line no-lonely-if
            if ($this.is('#inspiration') && playerInspiration) {
                playerInspiration.playVideo();
            } else if ($this.is('#learn') && playerLearn) {
                playerLearn.playVideo();
            }
        }
    });

    $welcomeTile.on('mouseout', function () {
        var $this = $(this);
        $welcomeTile.removeClass('unactive');

        if ($videoPlayerInspiration.length && $videoPlayerLearn.length) {
            if ($this.is('#inspiration')) {
                $videoPlayerInspiration.trigger('pause');
            } else if ($this.is('#learn')) {
                $videoPlayerLearn.trigger('pause');
            }
        } else {
            // eslint-disable-next-line no-lonely-if
            if ($this.is('#inspiration') && playerInspiration) {
                playerInspiration.pauseVideo();
            } else if ($this.is('#learn') && playerLearn) {
                playerLearn.pauseVideo();
            }
        }
    });

    $welcomeTile.on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();

        var strategy = this.id;
        $welcomeRow.attr('data-state', 'occasion').attr('data-strategy', strategy);
    });

    $resetSelection.on('click', function () {
        $welcomeRow.attr('data-state', 'question').removeAttr('data-strategy');
    });

    $welcomeStrategyBtn.on('click', function () {
        var $that = $(this);

        $logo.addClass('scale');
        $welcomeOverlay.addClass('show');
        $welcomeTopSectionItem.css('overflow', 'hidden');
        $body.css('overflow', 'hidden');

        // Set init state
        $.post($welcomeRow.attr('data-state-url'), {
            intent: $welcomeRow.attr('data-strategy'),
            occasion: $that.attr('id')
        })
        .then(function (response) {
            if (response && response.success) {
                var currentUrl = new URL(window.location.href);
                var params = new URLSearchParams(currentUrl.search);

                params.set('action', 'scroll');
                currentUrl.search = params.toString();

                window.history.replaceState(null, null, currentUrl.toString());
                location.reload();
            }
        });
        // Remove animation
        //     $logo.removeClass('scale');
        //     $welcomeOverlay.removeClass('show');
        //     $welcomeTopSectionItem.removeAttr('style');
        //     $body.removeAttr('style');
    });

    // Handle orientation change or resize
    $(window).on('orientationchange resize', function () {
        $welcomeRow.removeAttr('style').removeClass('initialized');
        initWelcomeComponent();
    });

    // Scroll to the next component on initial state init
    var currentUrl = new URL(window.location.href);
    var params = new URLSearchParams(currentUrl.search);

    if (params.has('action') && params.get('action') === 'scroll') {
        params.delete('action');
        currentUrl.search = params.toString();
        window.history.replaceState(null, null, currentUrl.toString());

        window.scrollBy({
            top: window.innerHeight,
            behavior: 'smooth'
        });
    }
    //
};
