'use strict';

/**
 * Initializes diamond room links by updating the URL of each link to include the product ID of the currently active product image.
 *
 * @return {undefined}
 */
function initDiamondRoomLinks() {
    var $buttons = $('.js-diamond-room-return-button');

    $buttons.each(function (index, e) {
        var $that = $(e);
        var $activeProductImg = $that.closest('.js-lm-dr-returning').find('.slick-current .dr-product-tile-image');

        if ($activeProductImg.length) {
            var pid = $activeProductImg.attr('data-pid');

            if (pid && $that.attr('data-href')) {
                var targetUrl = new URL($that.attr('data-href'));

                targetUrl.searchParams.append('pid', pid);
                $that.attr('href', targetUrl.href);
            }
        }
    });
}

/**
 * Init dr carousel
 */
function carouselInit() {
    const $carousel = $('.js-lm-dr-carousel');

    if ($carousel.find('.dr-product-tile').length === 2) {
        $carousel.find('.dr-product-tile').clone().appendTo($carousel);
    }

    if ($carousel.find('.dr-product-tile').length === 3) {
        $carousel.find('.dr-product-tile:eq(2)').clone().appendTo($carousel);
    }

    $carousel.slick({
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    centerMode: true,
                    centerPadding: '0',
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    });

    $carousel.on('afterChange', initDiamondRoomLinks);
}

module.exports = {
    carousel: carouselInit,
    initDiamondRoomLinks: initDiamondRoomLinks
};
