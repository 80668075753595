'use strict';

var slickConfigs = require('org/config/slickConfigs');

module.exports = function () {
    const $body = $('body');
    const $wishlistComponent = $('.js-lm-wishlist');
    const $wishlistCarousel = $('.js-lm-wishlist-carousel');
    const $carouselContainer = $('.js-lm-wishlist-carousel-container');

    $body.on('removeWishlistItem', function (e, productID) {
        const slideCount = $wishlistCarousel.slick('getSlick').slideCount;
        const $productTile = $wishlistCarousel.find(`#${productID}`);
        const $slide = $productTile.closest('.slick-slide');
        const slideIndex = $slide.data('slick-index');

        $wishlistCarousel.slick('slickRemove', slideIndex);

        if (slideCount === 1) {
            $carouselContainer.addClass('d-none');
            $wishlistComponent.addClass('empty');
        }
    });

    $body.on('addWishlistItem', function (e, productID) {
        if (!$wishlistCarousel.length) return;

        $.ajax({
            url: $('.js-tile-show').attr('data-url'),
            type: 'get',
            data: {
                pid: productID,
                pview: 'tile',
                ratings: false,
                swatches: false,
                affirm: false,
                shopSimilar: false,
                brand: false
            },
            success: function (data) {
                if ($('.js-lm-wishlist').hasClass('empty')) {
                    $('.js-lm-wishlist').removeClass('empty');
                }

                if ($carouselContainer.hasClass('d-none')) {
                    $wishlistCarousel.slick('slickSetOption', slickConfigs.wishlistCarousel, true);

                    $carouselContainer.removeClass('d-none');
                }

                $wishlistCarousel.slick('slickAdd', '<div><div> ' + data + ' </div></div>');
            },
            error: function (err) {
                if (err && err.responseJSON && err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
            }
        });
    });
};
