'use strict';

module.exports = function () {
    var $openQuickViewBtn = $('.js-open-quickview');
    var $quickViewOverlay = $('.js-lm-product-quickview-overlay');
    var $productQuickViewContainer = $('.js-lm-product-quickview-container');
    var customTileConfig = $quickViewOverlay.data('custom-config');
    var $body = $('body');
    var $closeQuickView = $('.js-close-product-quickview');

    var disableBodyScrolling = function () {
        $body.css({
            height: '100vh',
            overflow: 'hidden'
        });
    };

    var enableBodyScrolling = function () {
        $body.removeAttr('style');
    };

    var toggleOverlay = function () {
        if ($quickViewOverlay.hasClass('show')) {
            $quickViewOverlay
                .removeClass('show')
                .addClass('hiding');

            setTimeout(function () {
                $quickViewOverlay.removeClass('hiding');
                $productQuickViewContainer.empty();
                enableBodyScrolling();
            }, 800);
        } else {
            $quickViewOverlay.addClass('show');
            disableBodyScrolling();
            $quickViewOverlay.spinner().start();
        }
    };

    $openQuickViewBtn.on('click', function (e) {
        e.preventDefault();
        var $this = $(this);
        var quickViewURL = $this.attr('data-quickview-url');
        var customTileImageSrc = '';
        var customTileLink = '';
        var category;

        toggleOverlay();

        if ($this.is('.js-ls-cta')) {
            category = 'engagement-rings';
        } else if ($this.hasClass('discover-module-link')) {
            category = $this.attr('data-tile-category');
        } else {
            category = $quickViewOverlay.attr('data-category') || 'engagement-rings';
        }

        if (category === 'engagement-rings' || category === 'engagement-ring-discovery') {
            customTileImageSrc = customTileConfig.engagementProductImage;
            customTileLink = customTileConfig.engagementLink;
        } else if (category === 'mens-bands' || category === 'mens-wedding-band-discovery') {
            customTileImageSrc = customTileConfig.mensBandsProductImage;
            customTileLink = customTileConfig.mensBandsLink;
        } else if (category === 'womens-bands' || category === 'womens-wedding-band-discovery') {
            customTileImageSrc = customTileConfig.womensBandsProductImage;
            customTileLink = customTileConfig.womensBandsLinkImage;
        } else if (category === 'anniversary-rings' || category === 'anniversary-ring-discovery') {
            customTileImageSrc = customTileConfig.anniversaryProductImage;
            customTileLink = customTileConfig.anniversaryLink;
        }

        quickViewURL += `&category=${category}`;

        $.ajax({
            url: quickViewURL,
            type: 'get',
            success: function (htmlRes) {
                $productQuickViewContainer.html(htmlRes);
                var $customRingsTile = $('.js-custom-rings-tile');

                $customRingsTile.find('img').attr('src', customTileImageSrc);
                $customRingsTile.find('.js-custom-rings-link').attr('href', customTileLink);
            },
            complete: function () {
                $quickViewOverlay.spinner().stop();
            }
        });
    });

    $closeQuickView.on('click', function () {
        toggleOverlay();
    });
};
