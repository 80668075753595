'use strict';

var device = require('org/utils/device');

module.exports = function () {
    var $ringBuilderTile = $('.js-ring-builder-tile');
    var isDesktop = (device.desktop() || device.tablet()) && !device.mobileSm();

    $ringBuilderTile.each(function () {
        var bgImageData = $(this).data(isDesktop ? 'bg-image-desktop' : 'bg-image-mobile');

        $(this).css({
            'background-image': `url(${bgImageData.imageSrc})`,
            'background-position': `${bgImageData.focalPointX * 100}% ${bgImageData.focalPointY * 100}%`
        });
    });
};
