'use strict';

/**
 * Reusable slick carousel configurations
 * @example - $('.product-carousel').slick(slickConfigs.pdp)
 */

module.exports = {
    pdpRecommendations: {
        slidesToShow: 2.3,
        slidesToScroll: 2,
        infinite: false,
        dots: false,
        mobileFirst: true,
        rows: 0,
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2.3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4.6,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 5.6,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 1439,
                settings: {
                    slidesToShow: 6.2,
                    slidesToScroll: 3
                }
            }
        ]
    },
    hpRecommendations: {
        dots: false,
        arrows: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: true,
        centerPadding: '10%',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3.5,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2.5,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2.5,
                    focusOnSelect: true
                }
            }
        ]
    },
    hpShopbyShape: {
        dots: false,
        slidesToShow: 9,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4.5,
                    focusOnSelect: true,
                    arrows: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3.5,
                    focusOnSelect: true,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2.5,
                    focusOnSelect: true,
                    arrows: false
                }
            }
        ]
    },
    searchRecommendations: {
        dots: false,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    arrows: false
                }
            }
        ]
    },
    jewelryCarousel: {
        dots: false,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    },
    headerTopBanner: {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: true
    },
    storeDeatil: {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: false
    },
    homepageReviewsCarousel: {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: true
    },
    giftsBirthstoneCarousel: {
        dots: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    },
    plpCategoryCarousel: {
        dots: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.8
                }
            }
        ]
    },
    promotionalDrawerCarousel: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
        dots: true,
        arrows: false,
        mobileFirst: true,
        infinite: true,
        accessibility: false,
        centerMode: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    arrows: true,
                    centerMode: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    arrows: true,
                    centerMode: false
                }
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 5,
                    arrows: true,
                    centerMode: false
                }
            }
        ]
    },
    pdpContentSection: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
        dots: true,
        arrows: false,
        mobileFirst: true,
        infinite: true,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    centerMode: false
                }
            }
        ]
    },
    hpNineCentered: {
        dots: false,
        slidesToShow: 9,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    focusOnSelect: true
                }
            }
        ]
    },
    hpNineCenteredTwoMobile: {
        dots: false,
        slidesToShow: 9,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                    focusOnSelect: true
                }
            }
        ]
    },
    hpNineCenteredThreeMobile: {
        dots: false,
        slidesToShow: 9,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    centerMode: true,
                    focusOnSelect: true
                }
            }
        ]
    },
    hpSixCentered: {
        dots: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    focusOnSelect: true
                }
            }
        ]
    },
    hpFiveCentered: {
        dots: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    focusOnSelect: true
                }
            }
        ]
    },
    hpFourCentered: {
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    focusOnSelect: true
                }
            }
        ]
    },
    hpFourCenteredOneMobile: {
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    focusOnSelect: true
                }
            }
        ]
    },
    accountProductCarousel: {
        dots: false,
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.6,
                    arrows: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    arrows: false
                }
            }
        ]
    },
    promoBannerMessage: {
        dots: true,
        arrows: false,
        infinite: true,
        appendDots: $('.dots-container'),
        accessibility: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    arrows: false
                }
            }
        ]
    },

    cartRecommendations: {
        dots: false,
        arrows: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: true,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.6,
                    dots: false,
                    arrows: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    dots: false,
                    arrows: false
                }
            }
        ]
    },

    hpThreeHalfOneMobile: {
        dots: false,
        slidesToShow: 3.5,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        prevArrow: $('.vis-nav-prev-above'),
        nextArrow: $('.vis-nav-next-above'),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2.5,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.75,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.5,
                    focusOnSelect: true
                }
            }
        ]
    },

    educationCarousel: {
        infinite: false,
        slidesToScroll: 4,
        variableWidth: true,
        slidesToShow: 4.5,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToScroll: 3,
                    variableWidth: true,
                    slidesToShow: 3.5
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToScroll: 2,
                    variableWidth: true,
                    slidesToShow: 2.5
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 1.5,
                    variableWidth: true
                }
            }
        ]
    },

    educationModalCarousel: {
        infinite: false,
        slidesToScroll: 3,
        variableWidth: true,
        slidesToShow: 3.5,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToScroll: 3,
                    variableWidth: true,
                    slidesToShow: 3.5
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToScroll: 2,
                    variableWidth: true,
                    slidesToShow: 2.5
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 1.5,
                    variableWidth: true
                }
            }
        ]
    },

    wishlistCarousel: {
        arrows: true,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 576,
                settings: {
                    arrows: false,
                    slidesToShow: 1
                }
            }
        ]
    }
};
